export const educationData = [
  {
    id: 1,
    institution: "CMR Institute of Technology",
    course: "Bachelor of Engineering",
    startYear: "2021",
    endYear: "Present",
  },
  {
    id: 2,
    institution: "Sri Chaitanya Techno School",
    course: "Higher Secondary Education",
    startYear: "2018",
    endYear: "2021",
  },
];
