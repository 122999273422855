export const experienceData = [
  {
    id: 1,
    company: "Trigger Exam",
    jobtitle: "Full Stack Developer Intern | Bengaluru, Karnataka",
    startYear: "Sep 2023",
    endYear: "Dec 2023",
  },
  {
    id: 2,
    company: "Compsoft Technologies Pvt Ltd",
    jobtitle: "Machine Learning Intern | Remote",
    startYear: "Oct 2023",
    endYear: "Nov 2023",
  },
  {
    id: 3,
    company: "Varcons Inc.",
    jobtitle: "Java Development Intern | Remote",
    startYear: "Oct 2022 ",
    endYear: "Nov 2022",
  },
  {
    id: 4,
    company: "Verzeo",
    jobtitle: "Machine Learning Intern | Remote",
    startYear: "Jul 2022 ",
    endYear: "Aug 2022",
  },
];
