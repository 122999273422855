import one from "../assets/svg/projects/one.svg";
import two from "../assets/svg/projects/fifteen.svg";
import three from "../assets/svg/projects/three.png";
import four from "../assets/svg/projects/four.svg";
import five from "../assets/svg/projects/five.png";
import six from "../assets/svg/projects/six.svg";
import seven from "../assets/svg/projects/seven.svg";
import eight from "../assets/svg/projects/eight.svg";

export const projectsData = [
  {
    id: 1,
    projectName: "HirePrep",

    projectDesc:
      "A Full Stack AI Mock Interview website utilizing the Gemini API for generating and managing interview questions and answers.",

    tags: ["Next.js", "Drizzle ORM", "Gemini AI", "Clerk"],
    code: "https://github.com/shresthacodes/HirePrep",
    demo: "https://github.com/shresthacodes/HirePrep",
    image: one,
  },
  {
    id: 2,
    projectName: "Police Crime RMS",
    projectDesc:
      " Web-based application enabling law enforcement agencies to efficiently manage and document crime incidents with CRUD (Create, Read, Update, and Delete) operations.",
    tags: ["PHP", "Javascript", "CSS", "MySQL"],
    code: "https://github.com/shresthacodes/Police-Crime-Record-Management-System",
    demo: "https://github.com/shresthacodes/Police-Crime-Record-Management-System",
    image: two,
  },
  {
    id: 3,
    projectName: "Coffey",
    projectDesc: "User Friendly Coffee landing page template",
    tags: ["React", "CSS", "Material Ui"],
    code: "https://github.com/shresthacodes/Coffee-Landing-Page",
    demo: "https://coffey-psi.vercel.app/",
    image: three,
  },
  {
    id: 4,
    projectName: "Food Calorie Counter",
    projectDesc:
      "A web application that allows users to track their daily calorie intake using Django, Python, and an external API.",
    tags: ["Django", "Python"],
    code: "https://github.com/shresthacodes/Calorie-Tracker",
    demo: "https://github.com/shresthacodes/Calorie-Tracker",
    image: four,
  },
  {
    id: 5,
    projectName: "Medical Bot",
    projectDesc:
      "A question-answering system for medical queries using PDF documents as the knowledge base.",
    tags: ["Llama 2", "ChainLit"],
    code: "https://github.com/shresthacodes/Medical-Bot-using-llama2",
    demo: "https://github.com/shresthacodes/Medical-Bot-using-llama2",
    image: five,
  },
  /* {
    id: 6,
    projectName: "Uber Lite",
    projectDesc: "Uber clone",
    tags: ["Flutter"],
    code: "https://github.com/hhhrrrttt222111/developer-portfolio",
    demo: "https://github.com/hhhrrrttt222111/developer-portfolio",
    image: six,
  },*/
  {
    id: 7,
    projectName: "Stock Market App",
    projectDesc: "A simple stock market Webapp",
    tags: ["Streamlit", "Python"],
    code: "https://github.com/shresthacodes/Stockmarket-prediction-WebApp",
    demo: "https://github.com/shresthacodes/Stockmarket-prediction-WebApp",
    image: seven,
  },
  /* {
    id: 8,
    projectName: "Car Pooling System",
    projectDesc:
      "The carpooling system merges multiple people in a car which leads to meet new people, reduces pollution",
    tags: ["Flutter", "React"],
    code: "https://github.com/hhhrrrttt222111/developer-portfolio",
    demo: "https://github.com/hhhrrrttt222111/developer-portfolio",
    image: eight,
  },*/
];

// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/
