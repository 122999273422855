import resume from "../assets/pdf/resume.pdf";
import logo from "../assets/Profilepic/logo.png";
export const headerData = {
  name: "Shrestha Raj",
  title: "Web Developer",
  desciption:
    'My motto in life is "Becoming 1% better than yesterday" ~ a philosophy that emphasizes continuous growth and incremental improvement, recognizing that small, consistent steps forward can lead to significant progress over time.',
  image: logo,
  resumePdf: resume,
};
